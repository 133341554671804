@font-face {
  font-family: "LindeRegular";
  src: local("LindeRegular"),
    url(./assets/fonts/LindeDaxRegWeb.woff) format("woff");
}
* {
  font-family: LindeRegular;
}
.active {
  display: block;
}
.hidden,
[hidden] {
  display: none;
}
.disable-link-styles {
  color: inherit !important;
  text-decoration: none !important;
}
.position-relative {
  position: relative;
}
header.MuiAppBar-colorPrimary {
  background-color: #5e5e5e;
}
header {
  background-image: url("images/logo.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #015591 !important;
}
.disable-link-styles > div {
  opacity: 0.3;
}
.active > div {
  opacity: 1;
  cursor: default;
}
.loader {
  position: relative;
  text-align: center;
}
.d-flex {
  display: flex;
}
.no-gutters {
  margin-left: -10px;
  margin-right: -10px;
}
.inline-flex {
  display: inline-flex;
}
.d-inline-block {
  display: inline-block;
}
.float-right {
  float: right;
}
.ml-1 {
  margin-left: 10px;
}
.mr-2 {
  margin-right: 20px !important;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-1 {
  margin-top: 10px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2,
.divider {
  margin-bottom: 20px;
}
.m-1 {
  margin: 10px;
}
.mt-0 {
  margin-top: 0;
}
.divider {
  margin-top: 20px;
}
.z-index {
  position: relative;
  z-index: 1;
}
.MuiTableRow-head {
  background-color: #e1e1e1;
}
.MuiCardContent-root {
  padding: 0 !important;
}
.inline-flex .MuiTypography-root {
  margin: 10px 0 0 10px !important;
}
.data-card .MuiCardHeader-subheader {
  color: black;
  font-weight: bold;
}
.prediction-filters {
  text-align: right;
  margin-top: -40px;
}
.text-center {
  text-align: center;
}
.map-container {
  width: 100%;
  height: 75vh;
}
.marker {
  color: "white";
  background: "grey";
  padding: "15px 10px";
  display: "inline-flex";
  text-align: "center";
  align-items: "center";
  justify-content: "center";
  border-radius: "100%";
  transform: "translate(-50%, -50%)";
}
.w-100 {
  width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 108px;
}
.avatar-text {
  padding-right: 5px;
  font-size: 1rem;
}
.logo-text {
  float: right;
  font-size: 18px;
  color: white;
}

.logout-icon {
  font-size: 1.5rem;
  margin: 5px;
}
.logout-icon:hover {
  color: crimson;
}
.buttons-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
.button-style {
  background: #015591;
  border: 0;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.save-button {
  background: orange;
  margin-right: 5px;
}
.alert-container {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div[data-help="true"] {
  cursor: help;
}
.asterix-container {
  margin-left: 6%;
  font-size: 80%;
}
.asterix-container p {
  margin: 0 0 5px 0;
}
@media (max-width: 750px) {
  .prediction-filters {
    margin-top: 0px;
  }
  .logo-text {
    font-size: 11px;
  }
}
