.pin {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.pin svg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pin.bussines {
  background-image: url('../images/factory.png');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #000
}
.tooltip {
  border: 2px solid #015591;
  display: none;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  background-color: white;
  padding: 5px;
  width: auto;
}
.marker:hover .tooltip {
  display: block;
}
.tooltip p {
  white-space: nowrap;
  padding: 0;
  margin: 0;
}
